<template>
  <div class="table-responsive">
    <table
        class="
            table table-row-dashed table-row-gray-300
            align-middle
            gs-0
            gy-4
          "
    >
      <thead>
      <tr class="fw-bolder text-muted">
        <th class="min-w-50px">ID</th>
        <th class="min-w-100px">Name</th>
        <th class="min-w-120px text-end">Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="document_type in tableData" :key="document_type.id">
        <td>
            <span
                class="text-dark fw-bolder text-hover-primary d-block fs-6"
            >{{ document_type.id }}</span
            >
        </td>
        <td>
          <router-link
              :to="'/document-types/' + document_type.id"
              class="text-dark fw-bolder text-hover-primary fs-6"
          >{{ document_type.name }}</router-link>
        </td>
        <td class="text-end">
          <div class="btn-group btn-group-sm pb-2 pt-1" role="group">
            <button
                @click="emitEditEvent(document_type)"
                type="button"
                class="btn btn-sm btn-warning"
                data-bs-toggle="modal"
                data-bs-target="#DocumentTypeModal"
            >
              Edit
            </button>
            <button
                @click="openDeleteModal(document_type)"
                class="btn btn-sm btn-danger"
                data-bs-target="#deleteModal"
                data-bs-toggle="modal"
            >
              Delete
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "DocumentTypesDataTable",
  props: {
    tableData: Object
  },
  methods: {
    emitEditEvent(documentType) {
      this.$emit("editModalOpened", documentType);
    },
    openDeleteModal(documentType) {
      this.$emit("deleteModalOpened", documentType);
    }
  }
});
</script>
