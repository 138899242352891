<template>
  <!--begin:documentTypesIndex-->
  <div class="row gy-5 g-xl-8">
    <DocumentTypesIndex widget-classes="card-xxl-stretch mb-5 mb-xl-8"></DocumentTypesIndex>
  </div>
  <!--end::documentTypesIndex-->
</template>

<script lang="js">
import { defineComponent, onMounted } from "vue";
import DocumentTypesIndex from "@/components/settings/DocumenttypesIndex.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "DocumentTypesView",
  components: {
    DocumentTypesIndex
  },
  mounted() {
    setCurrentPageTitle("Document Types");
  }
});
</script>
