
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import DocumentTypeModal from "@/components/settings/modals/DocumentTypeModal.vue";
import DeleteModal from "@/components/general/DeleteModal.vue";
import DocumentTypesDataTable from "@/components/settings/Tables/DocumenttypesDataTable.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import Pagination from "@/components/pagination/Pagination.vue";

export default defineComponent({
  name: "DocumentTypesIndex",
  components: {
    DocumentTypesDataTable,
    DocumentTypeModal,
    DeleteModal,
    LoadingSpinner,
    Pagination
  },
  props: {
    widgetClasses: String
  },
  data() {
    return {
      currentlySelectedDocumentType: {
        id: null,
        name: null
      },
      isThereAModalActive: false,
      modalSetup: {
        action: "",
        title: ""
      },
      pagination: {}
    };
  },
  methods: {
    ...mapActions("DocumentTypesModule", [
      "fetchData",
      "createNew",
      "update",
      "destroyData",
      "setPageNumber",
      "resetPageNumber",
      "resetState"
    ]),
    changeSearchByPage(pageNumber) {
      this.setPageNumber(pageNumber);
      this.fetchData();
    },
    openAddNewModal() {
      this.modalSetup.title = "";
      this.$nextTick(() => this.modalSetup.title = "Add new Document Type");
      this.modalSetup.action = "addnew";
      this.isThereAModalActive = true;
    },
    saveDataFromModal(dataFromModal) {
      if (dataFromModal.action == "edit") {
        this.update({
          values: dataFromModal.payload,
          id: this.currentlySelectedDocumentType.id
        });
      } else {
        this.createNew(dataFromModal.payload);
      }
    },
    openEditModal(documentType) {
      this.modalSetup.title = "Edit Document Type";
      this.modalSetup.action = "edit";
      this.currentlySelectedDocumentType = documentType;
    },
    closeModal() {
      this.isThereAModalActive = false;
    },
    openDeleteModal(entry) {
      this.currentlySelectedDocumentType = entry;
      this.isThereAModalActive = true;
    },
    deleteSelectedDocumentType() {
      this.destroyData(this.currentlySelectedDocumentType.id);
      this.isThereAModalActive = false;
      this.currentlySelectedDocumentType.id = null;
    },
    resetDocumentTypeData() {
      this.currentlySelectedDocumentType = {
        id: null,
        name: null
      };
    }
  },
  computed: {
    ...mapGetters("DocumentTypesModule", [
      "documentTypesList",
      "loading",
      "getPaginationData"
    ])
  },
  created() {
    this.fetchData();
  },
  updated() {
    this.pagination = this.getPaginationData;
  },
  unmounted() {
    this.resetState();
  }
});
